<template>
  <div
    class="card"
    data-animation="true"
  >
    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
      <a
        class="d-flex blur-shadow-image justify-content-center align-items-end"
      >
        <img
          :src="img"
          alt="img-blur-shadow"
          class="img-fluid shadow border-radius-lg"
        >
      </a>
    </div>
    <div class="card-body text-center">
      <div class="d-flex mt-n6 justify-content-center">
        <a
          class="btn btn-link text-secondary border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Refresh"
        >
          <i class="material-icons text-lg">edit_calendar</i>
        </a>
        <button
          class="btn btn-link text-secondary border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Edit"
        >
          <i class="material-icons text-lg">edit</i>
        </button>
        <button
          class="btn btn-link text-secondary border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Delete"
          @click="deleteEntry()"
        >
          <i class="material-icons text-lg">delete</i>
        </button>
      </div>
      <h5 class="font-weight-normal mt-3">
        <a
          class="entry-title"
          href="javascript:;"
        >{{ title }}</a>
      </h5>
      <p class="entry-desc mb-0">
        {{ description }}
      </p>
    </div>
    <hr class="dark horizontal my-0">
    <div class="card-footer pt-3 d-flex">
      <i class="material-icons position-relative text-lg my-auto me-1">event</i>
      <p class="font-weight-normal my-auto">
        {{ price }}
      </p>
      <i class="material-icons position-relative ms-auto text-lg me-1 my-auto">cast</i>
      <p class="text-sm my-auto">
        {{ location }}
      </p>
    </div>
  </div>
</template>

<script>
import { db, doc, deleteDoc } from "@/firebase";

export default {
  name: "WatchlistCard",
  props: {
    did: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
  methods: {
    async deleteEntry() {
      switch(this.type) {
        case 'Filme':
          await deleteDoc(doc(db, "movies", this.did));
          break;
        case 'Serien':
          await deleteDoc(doc(db, "shows", this.did));
          break;
        case 'Videospiele':
          await deleteDoc(doc(db, "games", this.did));
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.entry-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.entry-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  hyphens: auto;
}
.card-header {
  &:first-child {
    background: none;
  }
}
</style>
