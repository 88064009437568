import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import { firebase, getAuth, onAuthStateChanged } from "./firebase";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import "./registerServiceWorker";

import { getAnalytics, logEvent } from "firebase/analytics";
import i18n from "./i18n";

const analytics = getAnalytics(firebase);
logEvent(analytics, "app_loaded");

const appInstance = createApp(App).use(i18n);
appInstance.config.devtools = true;
appInstance.use(store);


router.beforeEach((to, from, next) => {
  document.title = to.meta.nameDe ? to.meta.nameDe  + ' - dotter' : 'dotter';

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if(requiresAuth) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        appInstance.config.globalProperties.$user = user;
        next();
      } else {
        next("/signin");
      }
    });
  } else {
    next();
  }
});
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.mount("#app");
