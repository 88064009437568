<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row mb-4">
          <div class="col-lg-4 col-md-6 mt-4 mb-4">
            <chart-holder-card
              title="Körperfettanteil in %"
              subtitle="Kalendarwoche"
              update="Zuletzt vor 2 Tagen aktualsiert"
            >
              <reports-bar-chart
                :chart="{
                  labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                  datasets: {
                    label: 'Sales',
                    data: [50, 20, 10, 22, 50, 10, 40],
                  },
                }"
              />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 col-md-6 mt-4 mb-4">
            <chart-holder-card
              title="Schrittanzahl"
              subtitle="(<span class='font-weight-bolder'>+15%</span>) durchschnittliche Steigerung"
              update="Zuletzt vor 4 Minuten aktualsiert"
              color="success"
            >
              <reports-line-chart
                :chart="{
                  labels: [
                    'Apr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Dez',
                  ],
                  datasets: {
                    label: 'Mobile apps',
                    data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
                  },
                }"
              />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 mt-4 mb-3">
            <chart-holder-card
              title="Schlaf"
              subtitle="in Minuten"
              update="Gerade aktualisert"
              color="warning"
            >
              <reports-line-chart
                id="tasks-chart"
                :chart="{
                  labels: [
                    'Apr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Dez',
                  ],
                  datasets: {
                    label: 'Mobile apps',
                    data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                  },
                }"
              />
            </chart-holder-card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card
              :title="{ text: 'Kilokalorien', value: '1.281 kcal' }"
              detail="<span class='text-success text-sm font-weight-bolder'>+55%</span> mehr als letzte Woche"
              :icon="{
                name: 'local_fire_department',
                color: 'text-white',
                background: 'warning',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Schritte', value: '2.300' }"
              detail="<span class='text-success text-sm font-weight-bolder'>+3%</span> mehr als letzte Woche"
              :icon="{
                name: 'directions_walk',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Strecke', value: '14 km' }"
              detail="<span class='text-success text-sm font-weight-bolder'>+1%</span> mehr als letzten Monat"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Schlaf', value: '7,3 Stunden' }"
              detail="<span class='text-success text-sm font-weight-bolder'>+11%</span> mehr als letzten Monat"
              :icon="{
                name: 'bed',
                color: 'text-white',
                background: 'info',
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row" />
  </div>
</template>
<script>
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import ReportsLineChart from "./components/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";

export default {
  name: "DashboardDefault",
  components: {
    ChartHolderCard,
    ReportsBarChart,
    ReportsLineChart,
    MiniStatisticsCard,
  },
  data() {
    return {};
  },
};
</script>
