<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-4'"
  >
    <div class="px-4 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :color="color"
      />
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <ul
          class="navbar-nav justify-content-end"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <li class="nav-item dropdown d-flex align-items-center">
            <a
              id="dropdownMenuButton"
              href="#"
              class="d-flex p-0 nav-link lh-1"
              :class="[color, showMenu ? 'show' : '']"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="material-icons cursor-pointer"> notifications </i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                >
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/blank-profile.png"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      >
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Neue Mitteilung</span> von
                        Marvin
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1" />
                        Vor 13 Minuten
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                >
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm me-3"
                        alt="logo spotify"
                      >
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Neues Album</span> von
                        Schmyt
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1" />
                        Vor einem Tag
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="ps-3 nav-item d-flex align-items-center">
            <a
              class="d-flex p-0 nav-link lh-1"
              :class="color"
              @click="toggleDarkmode"
            >
              <i class="material-icons fixed-plugin-button-nav cursor-pointer">
                brightness_medium
              </i>
            </a>
          </li>
          <li class="nav-item d-flex ps-3 align-items-center">
            <router-link
              :to="{ name: 'Settings' }"
              class="d-flex px-0 nav-link font-weight-bold lh-1"
              :class="color"
            >
              <i class="material-icons">account_circle</i>
            </router-link>
          </li>
          <li class="nav-item d-xl-none d-flex ps-3 align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="d-flex p-0 nav-link text-body lh-1"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line" />
                <i class="sidenav-toggler-line" />
                <i class="sidenav-toggler-line" />
              </div>
            </a>
          </li>
          <li
            class="nav-item ps-3 d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              href="#"
              class="d-flex p-0 nav-link lh-1"
              :class="color"
              @click="logout"
            >
              <i class="material-icons">logout</i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "text-body",
    },
  },
  data() {
    return {
      showMenu: false,
      error: null,
    };
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),
    currentRouteName() {
      return this.$route.name ? this.$route.name : "";
    },
  },
  created() {
    this.minNav;
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
    toggleDarkmode() {
      if (this.$store.state.isDarkMode) {
        this.$store.state.isDarkMode = false;
        this.$store.state.sidebarType = "bg-white";
        deactivateDarkMode();
        return;
      } else {
        this.$store.state.isDarkMode = true;
        this.$store.state.sidebarType = "bg-gradient-dark";
        activateDarkMode();
      }
    },
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$router.push("/signin");
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
};
</script>
