<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header pb-3">
      <div class="row">
        <div class="col-11">
          <h5 class="mb-0">
            {{ name }}
          </h5>
          <p class="mb-0 text-sm">
            Synchronisiert mit Todoist
          </p>
        </div>
        <div class="col-1">
          <a
            href="#"
            @click="syncTasks"
          >
            <i class="material-icons text-lg">refresh</i>
          </a>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 pb-4">
      <div class="row mb-3">
        <div class="col-11">
          <material-input
            id="name"
            v-model="form.name"
            variant="static"
            placeholder="Neue Eintrag..."
          />
        </div>
        <div class="col-1 d-flex align-items-center">
          <a
            href="#"
            class="d-flex"
            @click="addTask"
          >
            <i class="material-icons">add</i>
          </a>
        </div>
      </div>

      <div
        v-for="(todo, index) in todos"
        :key="todo.taskId"
        class="row"
      >
        <div class="col-11">
          <div class="text-md font-weight-normal">
            {{ todo.content }}
          </div>
        </div>
        <div class="col-1 d-flex align-items-center">
          <a
            href="#"
            class="d-flex"
            @click="deleteTask(todo)"
          >
            <i class="material-icons text-lg">delete</i>
          </a>
        </div>
        <hr
          v-if="index < todos.length - 1"
          class="my-3 horizontal dark"
        >
      </div>
    </div>
  </div>
</template>
  
<script>
import MaterialInput from "@/components/MaterialInput.vue";

import { TodoistApi } from "@doist/todoist-api-typescript";

import { db, doc, getDoc, setDoc, deleteDoc, query, collection, where, getDocs, onSnapshot, limit, orderBy } from "@/firebase";

  export default {
    name: "TodoListCard",
    components: {
      MaterialInput
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      pid: {
        type: String,
        required: true,
      },
      limit: {
        type: Number,
        default: 0,
        required: false
      }
    },
    data: () => ({
      form: {
        name: ""
      },
      todos: [],
      unsubscribe: null
    }),
    async beforeMount() {

      if (this.$user) {
        const userDocRef = doc(db, 'users', this.$user.uid);
        let userDocSnap = await getDoc(userDocRef);
        this.userDoc = userDocSnap.data();

        let self = this;
        
        let q;
        if(this.limit > 0) {
          q = query(collection(db, "todos"), where("user", "==", self.$user.uid), where("projectId", "==", this.pid), orderBy('createdAt'), limit(this.limit));
        } else {
          q = query(collection(db, "todos"), where("user", "==", self.$user.uid), where("projectId", "==", this.pid), orderBy('createdAt', 'desc'));
        }
        this.unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const { newIndex, oldIndex, doc, type } = change
            if (type === 'added') {
              this.todos.splice(newIndex, 0, doc.data())
            } else if (type === 'modified') {
              this.todos.splice(oldIndex, 1)
              this.todos.splice(newIndex, 0, doc.data())
            } else if (type === 'removed') {
              this.todos.splice(oldIndex, 1)
            }
          })
        }, (error) => {
          console.error(error);
        });

        this.syncTasks();
      }
    },
    async beforeUnmount() {
      this.unsubscribe();
    },
    methods: {
      async addTask() {
        let self = this;
        const api = new TodoistApi(this.userDoc.todoist_api_token);

        api.addTask({ content: this.form.name, projectId: this.pid, labels: ['Alexa'] })
          .then((task) => {
            console.log(task);
            self.syncTasks();
          })
          .catch((error) => console.log(error));
      },
      deleteTask(todo) {
        let self = this;
        const api = new TodoistApi(this.userDoc.todoist_api_token);

        api.closeTask(todo.taskId)
          .then((success) => {
            console.log(success);
            self.syncTasks();
          })
          .catch((error) => console.log(error));
      },
      async syncTasks() {
        let self = this;
        const api = new TodoistApi(this.userDoc.todoist_api_token);

        api.getTasks({
          projectId: this.pid
        })
        .then(async function (tasks) {
          let taskIds = [];
          // Add/Update existing 
          tasks.forEach(async task => {
            taskIds.push(task.id);

            await setDoc(doc(db, "todos", task.id), {
              taskId: task.id,
              user: self.$user.uid,
              createdAt: task.createdAt,
              content: task.content,
              isCompleted: task.isCompleted,
              projectId: self.pid
            });
          });

          const q = query(collection(db, "todos"), where("projectId", "==", self.pid), where("user", "==", self.$user.uid));
          const querySnapshot = await getDocs(q);
          
          querySnapshot.forEach((docu) => {
            if(!taskIds.includes(docu.id)) {
              deleteDoc(doc(db, "todos", docu.id));
            }
          });
        })
        .catch((error) => console.error(error));     
      },
    },
  };
</script>

<style lang="scss">
.card-body {
  .col-11 {
    .text-md {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>