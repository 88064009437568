<template>
  <li class="nav-item active">
    <router-link
      class="nav-link"
      :to="to"
    >
      <span
        v-if="miniIcon"
        class="sidenav-mini-icon text-white"
      >
        {{ miniIcon }}
      </span>
      <div
        class="text-center d-flex align-items-center justify-content-center"
        :class="isRTL ? ' ms-2' : 'me-2'"
      >
        <slot name="icon" />
      </div>
      <span
        class="sidenav-normal text-white text-white"
        :class="isRTL ? ' me-1' : 'ms-1'"
      >
        {{ text }}
      </span>
    </router-link>
  </li>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    miniIcon: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
