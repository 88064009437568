<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <expenses-card
          title="Einnahmen und Ausgaben"
        />
        <savings-card
          title="Haushaltskonto"
        />
      </div>
      <div class="col-md-5">
        <div class="row mt-4">
          <div class="col-12">
            <chart-holder-card
              title="Haushaltskonto"
              subtitle="(<span class='font-weight-bolder'>+15%</span>) durchschnittliche Steigerung"
              update="Zuletzt vor 4 Minuten aktualsiert"
              color="success"
            >
              <reports-line-chart
                :chart="{
                  labels: [
                    'Apr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Dez',
                  ],
                  datasets: {
                    label: 'Mobile apps',
                    data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
                  },
                }"
              />
            </chart-holder-card>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6 mb-4">
            <default-info-card
              icon="payments"
              title="Gehalt"
              description="durch Arbeitgeber XY"
              value="+ 2.000,00€"
            />
          </div>
          <div class="col-md-6 mb-4">
            <default-info-card
              icon="account_balance_wallet"
              title="PayPal"
              description="aktuelles Guthaben"
              value="0,00€"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import ExpensesCard from "./components/ExpensesCard.vue";
import SavingsCard from "./components/SavingsCard.vue";
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsLineChart from "./components/ReportsLineChart.vue";

export default {
  name: "Billing",
  components: {
    ChartHolderCard,
    ReportsLineChart,
    DefaultInfoCard,
    ExpensesCard,
    SavingsCard
  },
};
</script>
