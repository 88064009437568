import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Analytics from "../views/dashboards/Analytics.vue";
import Sales from "../views/dashboards/Sales.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Projects from "../views/pages/profile/Projects.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";
import OrderDetails from "../views/ecommerce/orders/OrderDetails";
import OrderList from "../views/ecommerce/orders/OrderList";
import NewUser from "../views/pages/users/NewUser.vue";
import Settings from "../views/dots/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import Invoice from "../views/pages/account/Invoice.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";

import Finance from "../views/dots/Finance.vue";
import Fitness from "../views/dots/Fitness.vue";
import Recipes from "../views/dots/Recipes.vue";
import ShoppingList from "../views/dots/ShoppingList.vue";
import Watchlist from "../views/dots/Watchlist.vue";
import Wishlist from "../views/dots/Wishlist.vue";

import SpotifyCallback from "../views/dots/SpotifyCallback.vue";

const routes = [
  {
    path: "/",
    name: "Default",
    component: Default,
    meta: {
      requiresAuth: true,
      nameDe: "Dashboard"
    },
  },
  {
    path: "/dashboards/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/dashboards/sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      nameDe: "Einstellungen",
    },
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Basic,
    meta: {
      nameDe: "Anmelden",
    }
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupBasic,
    meta: {
      nameDe: "Registrieren",
    }
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },

  {
    path: "/finance",
    name: "Finance",
    component: Finance,
    meta: {
      requiresAuth: true,
      nameDe: "Finanzen",
    },
  },
  {
    path: "/fitness",
    name: "Fitness",
    component: Fitness,
    meta: {
      requiresAuth: true,
      nameDe: "Fitness",
    },
  },
  {
    path: "/recipes",
    name: "Recipes",
    component: Recipes,
    meta: {
      requiresAuth: true,
      nameDe: "Rezepte",
    },
  },
  {
    path: "/shopping-list",
    name: "ShoppingList",
    component: ShoppingList,
    meta: {
      requiresAuth: true,
      nameDe: "Einkaufsliste",
    },
  },
  {
    path: "/watchlist",
    name: "Watchlist",
    component: Watchlist,
    meta: {
      requiresAuth: true,
      nameDe: "Watchlist",
    },
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
    meta: {
      requiresAuth: true,
      nameDe: "Wunschliste",
    },
  },
  {
    path: "/spotify-callback",
    name: "SpotifyCallback",
    component: SpotifyCallback,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
