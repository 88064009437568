<template>
  <div class="pt-2 container-fluid">
    <div class="row">
      <div
        v-for="(list, index) in lists"
        :key="'list' + index"
        class="col-lg-6 mb-4"
      >
        <todo-list-card
          :name="list.name"
          :pid="list.pid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TodoListCard from "./components/TodoListCard.vue";

import { db, doc, getDoc } from "@/firebase";

export default {
  name: "DataTables",
  components: {
    TodoListCard
  },
  data: () => ({
    
    lists: []
  }),
  async beforeMount() {
    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();

      let self = this;

      const lists = this.userDoc.todoist_list_id.split(',');
      
      lists.forEach(async list => {
        self.lists.push({
          name: list.split(':')[0],
          pid: list.split(':')[1]
        });
      });
    }
  },
  methods: {
    
  },
};
</script>
