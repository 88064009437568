<template>
  <div
    class="card"
    :data-animation="location != 'Amazon' ? true : false"
  >
    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
      <a
        class="d-flex blur-shadow-image justify-content-center align-items-end"
      >
        <img
          :src="img"
          alt="img-blur-shadow"
          class="img-fluid shadow border-radius-lg"
        >
      </a>
    </div>
    <div class="card-body text-center">
      <div
        v-if="location != 'Amazon'"
        class="d-flex mt-n6 mx-auto"
      >
        <button
          class="btn btn-link text-secondary ms-auto border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Edit"
          @click="$parent.openEditModal(wish)"
        >
          <i class="material-icons text-lg">edit</i>
        </button>
        <button
          class="btn btn-link text-secondary me-auto border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="delete"
          @click="deleteWish"
        >
          <i
            class="material-icons text-lg"
          >delete</i>
        </button>
      </div>
      <h5 class="font-weight-normal mt-3">
        <a
          class="wish-title"
          href="javascript:;"
        >{{ title }}</a>
      </h5>
      <p class="mb-0">
        {{ description }}
      </p>
    </div>
    <hr class="dark horizontal my-0">
    <div class="card-footer pt-4 d-flex">
      <p class="font-weight-normal my-auto">
        {{ price }}
      </p>
      <i class="material-icons position-relative ms-auto text-lg me-1 my-auto">link</i>
      <p class="text-sm my-auto">
        {{ location }}
      </p>
    </div>
  </div>
</template>

<script>
import { db, doc, updateDoc, getDoc } from "@/firebase";

export default {
  name: "WishlistCard",
  props: {
    wish: {
      type: Object,
      required: true,
    },
    wishlistId: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
  methods: {
    async deleteWish() {
      let wishes = [];

      const pWishlistDocRef = doc(db, 'wishlists', this.wishlistId);
      let pWishlistDocSnap = await getDoc(pWishlistDocRef);
      wishes = pWishlistDocSnap.data().items;
      
      var i = wishes.length;
      while (i--) {
        if(wishes[i].title == this.title) {
          wishes.splice(i, 1);
        }
      }

      await updateDoc(doc(db, "wishlists", this.wishlistId), {
        items: wishes,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.blur-shadow-image {
  img {
    max-height: 200px;
  }
}
.wish-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card-header {
  a {
    height: 200px;
  }

  &:first-child {
    background: none;
  }
}
</style>
