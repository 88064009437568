<template>
  <div
    class="card"
    data-animation="true"
  >
    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
      <a class="d-block blur-shadow-image">
        <img
          :src="img"
          alt="img-blur-shadow"
          class="img-fluid shadow border-radius-lg w-100"
        >
      </a>
      <div
        class="colored-shadow"
        :style="`background-image: url(${img})`"
      />
    </div>
    <div class="card-body text-center">
      <div class="d-flex mt-n6 mx-auto">
        <a
          class="btn btn-link text-secondary ms-auto border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Refresh"
        >
          <i class="material-icons text-lg">link</i>
        </a>
        <a
          class="btn btn-link text-secondary mx-auto border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Refresh"
        >
          <i class="material-icons text-lg">shopping_bag</i>
        </a>
        <button
          class="btn btn-link text-secondary me-auto border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Edit"
          @click="$parent.openEditModal(recipe)"
        >
          <i class="material-icons text-lg">edit</i>
        </button>
        <button
          class="btn btn-link text-secondary me-auto border-0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Delete"
          @click="deleteRecipe()"
        >
          <i class="material-icons text-lg">delete</i>
        </button>
      </div>
      <h5 class="font-weight-normal mt-3">
        <a href="javascript:;">{{ title }}</a>
      </h5>
      <p class="mb-0">
        {{ description }}
      </p>
    </div>
    <hr class="dark horizontal my-0">
    <div class="card-footer d-flex">
      <i class="material-icons position-relative text-success my-auto">eco</i>
      <p class="font-weight-normal my-auto">
        {{ price }}
      </p>
      <i class="material-icons position-relative ms-auto text-lg me-1 my-auto">schedule</i>
      <p class="text-sm my-auto">
        {{ location }}
      </p>
    </div>
  </div>
</template>

<script>
import { db, doc, deleteDoc } from "@/firebase";

export default {
  name: "RecipeCard",
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    did: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
  methods: {
    async deleteRecipe() {
      await deleteDoc(doc(db, "recipes", this.did));
    }
  }
};
</script>
