<template>
  <div class="pt-2 container-fluid">
    Du wirst gleich weitergeleitet...
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SpotifyCallback",
  components: {

  },
  data: () => ({

  }),
  beforeMount() {
    const queryString = window.location.search;
    var self = this;

    axios.get('https://auth-3elupsvpoa-nw.a.run.app/callback' + queryString)
    .then(function (response) {
      var data = JSON.stringify(response.data);
      self.$router.push({
        name: "Settings",
        params: { data }
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  methods: {
    
  },
};
</script>
