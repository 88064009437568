<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      />
      <router-link
        class="m-0 navbar-brand"
        to="/"
      >
        <img
          :src="isDarkMode ? logoDark : logo"
          class="navbar-brand-img h-100"
          alt="main_logo"
        >
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2">
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo.svg";
import logoDark from "@/assets/img/logo-reversed.svg";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
  },
  mounted() {
    var self = this;

    window.addEventListener('click', function(e){
      if (!document.getElementById('sidenav-main').contains(e.target) && !document.getElementById('iconNavbarSidenav').contains(e.target)) {
        const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

        if (sidenavShow.classList.contains("g-sidenav-pinned")) {
          self.navbarMinimize();
        }
      }
    });
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
  },

};
</script>
