<template>
  <div
    id="profile"
    class="card card-body mt-4"
  >
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <!--<material-avatar
          :img="img"
          alt="bruce"
          size="xl"
          shadow="sm"
          circular
        />-->
        <div class="avatar avatar-xl">
          <img
            src="../../../assets/img/blank-profile.png"
            class="shadow-sm rounded-circle"
          >
        </div>
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">
            {{ userDoc.first_name }} {{ userDoc.last_name }}
          </h5>
          <p class="mb-0 font-weight-bold text-sm">
            User-ID: {{ $user.uid }}
          </p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <!--<label class="form-check-label mb-0 me-2">
                 <small id="profileVisibility">Switch to invisible</small>
               </label>
        <material-switch
          id="switchInvisible"
          name="Switch to Invisible"
          checked
        />-->
      </div>
    </div>
  </div>
  <div
    id="basic-info"
    class="card mt-4"
  >
    <div class="card-header">
      <h5>Persönliche Daten</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <material-input
            id="firstName"
            v-model="userDoc.first_name"
            variant="static"
            label="Vorname"
            placeholder="Vorname"
          />
        </div>
        <div class="col-6">
          <material-input
            id="lastName"
            v-model="userDoc.last_name"
            variant="static"
            label="Nachname"
            placeholder="Nachname"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="row">
            <div class="col-sm-4 col-3">
              <label class="form-label mt-4 ms-0">Geburtsdatum</label>
              <select
                id="choices-day"
                v-model="userDoc.birthdate_day"
                class="form-control"
                name="choices-day"
              />
            </div>
            <div class="col-sm-5 col-5">
              <label class="form-label mt-4 ms-0">&nbsp;</label>
              <select
                id="choices-month"
                v-model="userDoc.birthdate_month"
                class="form-control"
                name="choices-month"
              />
            </div>
            <div class="col-sm-3 col-4">
              <label class="form-label mt-4 ms-0">&nbsp;</label>
              <select
                id="choices-year"
                v-model="userDoc.birthdate_year"
                class="form-control"
                name="choices-year"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <label class="form-label mt-4 ms-0">Sprache</label>
          <select
            id="choices-language"
            v-model="userDoc.language"
            class="form-control"
            name="choices-language"
          >
            <option value="German">
              Deutsch
            </option>
            <option value="English">
              Englisch
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="email"
            v-model="userDoc.email"
            type="email"
            variant="static"
            label="E-Mail-Adresse"
            placeholder="beispiel@email.com"
          />
        </div>
        <div class="col-6">
          <material-input
            id="confirmEmail"
            v-model="userDoc.email_confirm"
            type="email"
            variant="static"
            label="E-Mail-Adresse bestätigen"
            placeholder="beispiel@email.com"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <material-input
            id="location"
            v-model="userDoc.household"
            type="text"
            variant="static"
            label="Haushalt-ID"
            placeholder="Haushalt-ID"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <material-input
            id="expensesCategories"
            v-model="userDoc.expenses_categories"
            type="text"
            variant="static"
            label="Kategorien für Ausgaben"
            placeholder=""
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <material-input
            id="savingsCategories"
            v-model="userDoc.savings_categories"
            type="text"
            variant="static"
            label="Kategorien für Haushaltskonto"
            placeholder=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 align-self-center">
          <material-button
            class="float-end mt-4 mb-0"
            color="warning"
            variant="gradient"
            size="sm"
            @click="savePersonals"
          >
            Änderungen speichern
          </material-button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="password"
    class="card mt-4"
  >
    <div class="card-header">
      <h5>Passwort ändern</h5>
    </div>
    <div class="card-body pt-0">
      <form>
        <div class="mb-3 d-none">
          <material-input
            id="username"
            type="text"
            label="Benutzername"
            autocomplete="username"
          />
        </div>
        <div class="mb-3">
          <material-input
            id="currentPassword"
            v-model="currentPassword"
            type="password"
            label="Akutelles Passwort"
            autocomplete="current-password"
          />
        </div>
        <div class="mb-3">
          <material-input
            id="newPassword"
            v-model="newPassword"
            type="password"
            label="Neues Passwort"
            autocomplete="new-password"
          />
        </div>
        <div class="mb-3">
          <material-input
            id="confirmPassword"
            v-model="confirmPassword"
            type="password"
            label="Passwort bestätigen"
            autocomplete="new-password"
          />
        </div>
      </form>
      <h5 class="mt-5">
        Passwortanforderungen
      </h5>
      <p class="text-muted mb-2">
        Es sind folgende Anforderungen für ein starkes Passwort einzuhalten:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">Mindestens 8 Zeichen</span>
        </li>
        <li>
          <span class="text-sm">Mindestens ein Sonderzeichen</span>
        </li>
        <li>
          <span class="text-sm">Mindestens einen Buchstaben</span>
        </li>
        <li>
          <span class="text-sm">Mindestens eine Ziffer</span>
        </li>
      </ul>
      <material-button
        class="float-end mt-6 mb-0"
        color="warning"
        variant="gradient"
        size="sm"
        @click="savePassword"
      >
        Passwort ändern
      </material-button>
    </div>
  </div>
  <!--<div
    id="2fa"
    class="card mt-4"
  >
    <div class="card-header d-flex">
      <h5 class="mb-0">
        Two-factor authentication
      </h5>
      <material-badge
        color="success"
        class="ms-auto"
      >
        Aktiv
      </material-badge>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <p class="my-auto">
          Security keys
        </p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          No Security Keys
        </p>
        <material-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
        >
          Add
        </material-button>
      </div>
      <hr class="horizontal dark">
      <div class="d-flex">
        <p class="my-auto">
          SMS number
        </p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          +4012374423
        </p>
        <material-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
        >
          Edit
        </material-button>
      </div>
      <hr class="horizontal dark">
      <div class="d-flex">
        <p class="my-auto">
          Authenticator app
        </p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          Not Configured
        </p>
        <material-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
        >
          Set up
        </material-button>
      </div>
    </div>
  </div>-->
  <div
    id="accounts"
    class="card mt-4"
  >
    <div class="card-header">
      <h5>Schnittstellen und Accounts</h5>
      <p class="text-sm">
        Hier kannst du Integrationen einrichten und verwalten.
      </p>
    </div>
    <div class="card-body pt-0">
      <!-- Spotify -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-2"
          :img="logoSpotify"
          alt="logo_spotify"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              Spotify
            </h5>
            <p
              v-if="!userDoc.spotify_enabled"
              class="mb-0 text-sm"
            >
              Musik-Streaming-Dienst
            </p>
            <a
              v-if="userDoc.spotify_enabled"
              class="text-sm text-body collapse-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseSpotify"
              role="button"
              aria-expanded="false"
              aria-controls="collapseSpotify"
            >
              <span class="collapsed">Mehr anzeigen</span>
              <span class="not-collapsed">Weniger anzeigen</span>
              <i
                class="fas fa-chevron-up text-xs ms-1"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <p
          v-if="userDoc.spotify_enabled"
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Aktiv
        </p>
        <p
          v-else
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Inaktiv
        </p>        
        <div class="my-auto">
          <material-switch
            id="enableSpotify"
            name="Spotify"
            :model-value="userDoc.spotify_enabled"
            @click="spotifyAuth"
          />
        </div>
      </div>
      <div
        v-if="userDoc.spotify_enabled"
        id="collapseSpotify"
        class="ps-5 pt-3 ms-3 collapse"
      >
        <p class="mb-0 text-sm">
          Musik-Streaming-Dienst
        </p>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Access Token
          </p>
          <input
            v-model="userDoc.spotify_access_token"
            class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
            type="text"
          >
        </div>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Refresh Token
          </p>
          <input
            v-model="userDoc.spotify_refresh_token"
            class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
            type="text"
          >
        </div>
        <div
          v-if="userDoc.spotify_access_token != null && userDoc.spotify_refresh_token != null && userDoc.spotify_access_token != '' && userDoc.spotify_refresh_token != ''"
          class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Account-Verknüfung trennen
          </p>
          <h6 class="text-sm ms-auto me-3 my-auto" />
          <material-button
            color="danger"
            variant="gradient"
            size="sm"
            class="my-sm-auto mt-2 mb-0"
            type="button"
            name="button"
            @click="disconnectSpotify"
          >
            Trennen
          </material-button>
        </div>
      </div>
      <hr class="horizontal dark">

      <!-- Todoist -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-2"
          :img="logoTodoist"
          alt="logo_todoist"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              todoist
            </h5>
            <p
              v-if="!userDoc.todoist_enabled"
              class="mb-0 text-sm"
            >
              Listen-Synchronisation
            </p>
            <a
              v-if="userDoc.todoist_enabled"
              class="text-sm text-body collapse-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseTodoist"
              role="button"
              aria-expanded="false"
              aria-controls="collapseTodoist"
            >
              <span class="collapsed">Mehr anzeigen</span>
              <span class="not-collapsed">Weniger anzeigen</span>
              <i
                class="fas fa-chevron-up text-xs ms-1"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <p
          v-if="userDoc.todoist_enabled"
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Aktiv
        </p>
        <p
          v-else
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Inaktiv
        </p>
        <div class="my-auto">
          <material-switch
            id="enableTodoist"
            v-model="userDoc.todoist_enabled"
            name="Todoist"
          />
        </div>
      </div>
      <div
        v-if="userDoc.todoist_enabled"
        id="collapseTodoist"
        class="ps-5 pt-3 ms-3 collapse"
      >
        <p class="mb-0 text-sm">
          Listen-Synchronisation
        </p>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            API-Token
          </p>
          <input
            v-model="userDoc.todoist_api_token"
            class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
            type="text"
          >
        </div>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Listen-IDs
          </p>
          <input
            v-model="userDoc.todoist_list_id"
            class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
            type="text"
          >
        </div>
      </div>
      <hr class="horizontal dark">

      <!-- TMDB -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-1"
          :img="logoTmdb"
          alt="logo_tmdb"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              The Movie Database (TMDB)
            </h5>
            <p
              v-if="!userDoc.tmdb_enabled"
              class="mb-0 text-sm"
            >
              Film- und Serien-Datenbank
            </p>
            <a
              v-if="userDoc.tmdb_enabled"
              class="text-sm text-body collapse-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseTmdb"
              role="button"
              aria-expanded="false"
              aria-controls="collapseTmdb"
            >
              <span class="collapsed">Mehr anzeigen</span>
              <span class="not-collapsed">Weniger anzeigen</span>
              <i
                class="fas fa-chevron-up text-xs ms-1"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <p
          v-if="userDoc.tmdb_enabled"
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Aktiv
        </p>
        <p
          v-else
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Inaktiv
        </p>
        <div class="my-auto">
          <material-switch
            id="enableTmdb"
            v-model="userDoc.tmdb_enabled"
            name="TMDB"
          />
        </div>
      </div>
      <div
        v-if="userDoc.tmdb_enabled"
        id="collapseTmdb"
        class="ps-5 pt-3 ms-3 collapse"
      >
        <p class="mb-0 text-sm">
          Film- und Serien-Datenbank
        </p>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            API Key
          </p>
          <input
            v-model="userDoc.tmdb_api_key"
            class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
            type="text"
          >
        </div>
      </div>
      <hr class="horizontal dark">

      <!-- IGDB -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-1"
          :img="logoIgdb"
          alt="logo_igdb"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              IGDB.com
            </h5>
            <p
              v-if="!userDoc.igdb_enabled"
              class="mb-0 text-sm"
            >
              Videospiel-Datenbank
            </p>
            <a
              v-if="userDoc.igdb_enabled"
              class="text-sm text-body collapse-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseIgdb"
              role="button"
              aria-expanded="false"
              aria-controls="collapseIgdb"
            >
              <span class="collapsed">Mehr anzeigen</span>
              <span class="not-collapsed">Weniger anzeigen</span>
              <i
                class="fas fa-chevron-up text-xs ms-1"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <p
          v-if="userDoc.igdb_enabled"
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Aktiv
        </p>
        <p
          v-else
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Inaktiv
        </p>
        <div class="my-auto">
          <material-switch
            id="enableIdgb"
            v-model="userDoc.igdb_enabled"
            name="IGDB"
            @click="igdbAuth"
          />
        </div>
      </div>
      <div
        v-if="userDoc.igdb_enabled"
        id="collapseIgdb"
        class="ps-5 pt-3 ms-3 collapse"
      >
        <p class="mb-0 text-sm">
          Videospiel-Datenbank
        </p>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Access Token
          </p>
          <input
            v-model="userDoc.igdb_access_token"
            class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
            type="text"
          >
        </div>
      </div>
      <hr class="horizontal dark">

      <!-- Chefkoch -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-2"
          :img="logoChefkoch"
          alt="logo_chefkoch"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              Chefkoch
            </h5>
            <p
              v-if="!userDoc.chefkoch_enabled"
              class="mb-0 text-sm"
            >
              Rezeptanbieter
            </p>
            <a
              v-if="userDoc.chefkoch_enabled"
              class="text-sm text-body collapse-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseChefkoch"
              role="button"
              aria-expanded="false"
              aria-controls="collapseChefkoch"
            >
              <span class="collapsed">Mehr anzeigen</span>
              <span class="not-collapsed">Weniger anzeigen</span>
              <i
                class="fas fa-chevron-up text-xs ms-1"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <p
          v-if="userDoc.chefkoch_enabled"
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Aktiv
        </p>
        <p
          v-else
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Inaktiv
        </p>
        <div class="my-auto">
          <material-switch
            id="enableChefkoch"
            v-model="userDoc.chefkoch_enabled"
            name="Chefkoch"
          />
        </div>
      </div>
      <div
        v-if="userDoc.chefkoch_enabled"
        id="collapseChefkoch"
        class="ps-5 pt-3 ms-3 collapse"
      >
        <p class="mb-0 text-sm">
          Rezeptanbieter
        </p>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            API URL
          </p>
          <input
            v-model="userDoc.chefkoch_url"
            class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
            type="text"
          >
        </div>
      </div>
      <hr class="horizontal dark">

      <!-- Amazon -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-2"
          :img="logoAmazon"
          alt="logo_amazon"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              Amazon (Wunschlisten)
            </h5>
            <p
              v-if="!userDoc.amazon_enabled"
              class="mb-0 text-sm"
            >
              Wunschlisten-Synchronisation 
            </p>
            <a
              v-if="userDoc.amazon_enabled"
              class="text-sm text-body collapse-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseAmazon"
              role="button"
              aria-expanded="false"
              aria-controls="collapseAmazon"
            >
              <span class="collapsed">Mehr anzeigen</span>
              <span class="not-collapsed">Weniger anzeigen</span>
              <i
                class="fas fa-chevron-up text-xs ms-1"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <p
          v-if="userDoc.amazon_enabled"
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Aktiv
        </p>
        <p
          v-else
          class="text-sm text-secondary ms-auto me-3 my-auto"
        >
          Inaktiv
        </p>
        <div class="my-auto">
          <material-switch
            id="enableAmazon"
            v-model="userDoc.amazon_enabled"
            name="Amazon"
          />
        </div>
      </div>
      <div
        v-if="userDoc.amazon_enabled"
        id="collapseAmazon"
        class="ps-5 pt-3 ms-3 collapse"
      >
        <p class="mb-0 text-sm">
          Wunschlisten-Synchronisation (IDs sind kommagetrennt zu hinterlegen)
        </p>
        <div class="row">
          <div
            class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
          >
            <p class="text-sm font-weight-bold my-auto ps-sm-2">
              Wunschlisten-IDs
            </p>
            <input
              v-model="userDoc.amazon_wishlists"
              class="form-control form-control-sm ms-sm-auto mt-sm-0 mt-2 w-sm-80 w-80"
              type="text"
            >
          </div>
        </div>
      </div>
      <hr class="horizontal dark">

      <!-- Comdirect -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-1"
          :img="logoComdirect"
          alt="logo_comdirect"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              comdirect
            </h5>
            <p class="mb-0 text-sm">
              Online-Bank
            </p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">
          Nicht implementiert
        </p>
        <div class="my-auto">
          <material-switch
            id="enableComdirect"
            name="Comdirect"
            disabled
          />
        </div>
      </div>
      <hr class="horizontal dark">

      <!-- Google Calendar -->
      <div class="d-flex">
        <material-avatar
          class="width-48-px p-2"
          :img="logoGCalendar"
          alt="logo_gcalendar"
        />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">
              Google Kalender
            </h5>
            <p class="mb-0 text-sm">
              Kalender
            </p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">
          Nicht implementiert
        </p>
        <div class="my-auto">
          <material-switch
            id="enableAtlassian"
            name="Atlassian"
            checked
            disabled
          />
        </div>
      </div>
      
      <div class="row">
        <div class="col-md-12 align-self-center">
          <material-button
            class="float-end mt-4 mb-0"
            color="warning"
            variant="gradient"
            size="sm"
            @click="saveAccounts"
          >
            Änderungen speichern
          </material-button>
        </div>
      </div>
    </div>
  </div>
  <!--<div
    id="notifications"
    class="card mt-4"
  >
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th
                class="ps-1"
                colspan="4"
              >
                <p class="mb-0">
                  Activity
                </p>
              </th>
              <th class="text-center">
                <p class="mb-0">
                  Email
                </p>
              </th>
              <th class="text-center">
                <p class="mb-0">
                  Push
                </p>
              </th>
              <th class="text-center">
                <p class="mb-0">
                  SMS
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                class="ps-1"
                colspan="4"
              >
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal">Notify when another user mentions you in a comment</span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsEmail"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Push"
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions SMS"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                class="ps-1"
                colspan="4"
              >
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal">Notify when another user comments your item.</span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="commentsEmail"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Comments Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="commentsPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Comments Push"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="commentsSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Comments SMS"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                class="ps-1"
                colspan="4"
              >
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal">Notify when another user follows you.</span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="followEmail"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Follow Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="followPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Follow Push"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="followSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Follow SMS"
                    checked
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                class="ps-1"
                colspan="4"
              >
                <div class="my-auto">
                  <p class="text-sm mb-0">
                    Log in from a new device
                  </p>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="login Email"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Login Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="loginPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Login Push"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="LoginSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Login SMS"
                    checked
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>-->
  <!--<div
    id="sessions"
    class="card mt-4"
  >
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6" />
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">
              Bucharest 68.133.163.201
            </p>
            <p class="mb-0 text-xs">
              Your current session
            </p>
          </div>
        </div>
        <material-badge
          color="success"
          size="sm"
          class="my-auto ms-auto me-3"
        >
          Active
        </material-badge>
        <p class="text-secondary text-sm my-auto me-3">
          EU
        </p>
        <a
          href="javascript:;"
          class="text-success text-sm icon-move-right my-auto"
        >
          See more
          <i
            class="fas fa-arrow-right text-xs ms-1"
            aria-hidden="true"
          />
        </a>
      </div>
      <hr class="horizontal dark">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6" />
        </div>
        <p class="my-auto ms-3">
          Chrome on macOS
        </p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          US
        </p>
        <a
          href="javascript:;"
          class="text-success text-sm icon-move-right my-auto"
        >
          See more
          <i
            class="fas fa-arrow-right text-xs ms-1"
            aria-hidden="true"
          />
        </a>
      </div>
      <hr class="horizontal dark">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6" />
        </div>
        <p class="my-auto ms-3">
          Safari on iPhone
        </p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          US
        </p>
        <a
          href="javascript:;"
          class="text-success text-sm icon-move-right my-auto"
        >
          See more
          <i
            class="fas fa-arrow-right text-xs ms-1"
            aria-hidden="true"
          />
        </a>
      </div>
    </div>
  </div>-->
  <div
    id="delete"
    class="card mt-4"
  >
    <div class="card-header">
      <h5>Account löschen</h5>
      <p class="text-sm mb-0">
        Das Löschen deines Accounts ist unwiderruflich.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <material-switch
            id="deleteAccount"
            v-model="confirmDelete"
            name="Delete Account"
          />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm">Bestätigen</span>
          <span class="text-xs d-block">Ich möchte meinen Account wirklich löschen.</span>
        </div>
      </div>
      <material-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        @click="deleteAccount"
      >
        Account löschen
      </material-button>
    </div>
  </div>
  <div class="position-fixed bottom-1 end-1 z-index-2">
    <material-snackbar
      v-if="snackbar === 'success'"
      title="dotter"
      date="Vor weniger als einer Minute"
      description="Änderungen gespeichert!"
      :icon="{ component: 'done', color: 'white' }"
      color="success"
      :close-handler="closeSnackbar"
    />
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
//import MaterialBadge from "@/components/MaterialBadge.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

import axios from "axios";

import img from "../../../assets/img/bruce-mars.jpg";

import logoGCalendar from "../../../assets/img/small-logos/logo-googlecalendar.svg";
import logoAmazon from "../../../assets/img/small-logos/logo-amazon.svg";
import logoTmdb from "../../../assets/img/small-logos/logo-tmdb.svg";
import logoIgdb from "../../../assets/img/small-logos/logo-igdb.svg";
import logoTodoist from "../../../assets/img/small-logos/logo-todoist.svg";
import logoChefkoch from "../../../assets/img/small-logos/logo-chefkoch.svg";
import logoSpotify from "../../../assets/img/small-logos/logo-spotify.svg";
import logoComdirect from "../../../assets/img/small-logos/logo-comdirect.png";

import { db, doc, getDoc, setDoc, updateDoc, getAuth, updatePassword, signInWithEmailAndPassword, deleteUser } from "@/firebase";

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialSwitch,
    /*MaterialBadge,*/
    MaterialAvatar,
    MaterialInput,
    MaterialSnackbar,
  },
  data() {
    return {
      img,
      logoGCalendar,
      logoAmazon,
      logoTmdb,
      logoIgdb,
      logoTodoist,
      logoChefkoch,
      logoSpotify,
      logoComdirect,
      userDoc: {},
      snackbar: null,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      confirmDelete: false
    };
  },
  async beforeMount() {
    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();
      
      if(this.$route.params && this.$route.params.data) {
        var spotifyData = JSON.parse(this.$route.params.data);

        await updateDoc(userDocRef, {
            spotify_access_token: spotifyData.access_token,
            spotify_refresh_token: spotifyData.refresh_token,
            spotify_enabled: true
        });
        userDocSnap = await getDoc(userDocRef);
        this.userDoc = userDocSnap.data();
      }
    }
  },
  async mounted() {
    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("expensesCategories")) {
      var expensesCategories = document.getElementById("expensesCategories");
      new Choices(expensesCategories, {
        removeItemButton: true,
        classNames: {
          inputCloned: 'choices__input--cloned flex-fill',
        },
        addItemText: (value) => {
        return `Drücke 'Enter' um <b>"${value}"</b> hinzuzufügen.`;
        },
        maxItemText: (maxItemCount) => {
          return `Maximal ${maxItemCount} können hinzugefügt werden.`;
        },
      });
    }    
    if (document.getElementById("savingsCategories")) {
      var savingsCategories = document.getElementById("savingsCategories");
      new Choices(savingsCategories, {
        removeItemButton: true,
        classNames: {
          inputCloned: 'choices__input--cloned flex-fill',
        },
        addItemText: (value) => {
        return `Drücke 'Enter' um <b>"${value}"</b> hinzuzufügen.`;
        },
        maxItemText: (maxItemCount) => {
          return `Maximal ${maxItemCount} können hinzugefügt werden.`;
        },
      });
    }    

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month);
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "Januar";
      monthArray[1] = "Februar";
      monthArray[2] = "März";
      monthArray[3] = "April";
      monthArray[4] = "Mai";
      monthArray[5] = "Juni";
      monthArray[6] = "Juli";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "Oktober";
      monthArray[10] = "November";
      monthArray[11] = "Dezember";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == this.userDoc.birthdate_month) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day);
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == this.userDoc.birthdate_day) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year);
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == this.userDoc.birthdate_year) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
  methods: {
    async savePersonals() {
      if (this.$user) {
        const userDocRef = doc(db, 'users', this.$user.uid);
        
        this.userDoc.expenses_categories = document.getElementById('expensesCategories').value;
        this.userDoc.savings_categories = document.getElementById('savingsCategories').value;
        console.log(this.userDoc);

        await setDoc(userDocRef, this.userDoc);

        const userDocSnap = await getDoc(userDocRef);
        this.userDoc = userDocSnap.data();

        this.snackbar = 'success';
      }
    },
    savePassword() {
      console.log(this.currentPassword);
      console.log(this.userDoc.email);
      if(this.currentPassword != '' && this.newPassword != '' && this.confirmPassword != '') {
        if(this.newPassword == this.confirmPassword) {
          const auth = getAuth();
          var self = this;
          signInWithEmailAndPassword(auth, this.userDoc.email, this.currentPassword)
            .then((userCredential) => {
              // Signed in 
              const user = userCredential.user;
              this.$user

              updatePassword(user, self.newPassword).then(() => {
                this.snackbar = 'success';
              }).catch((error) => {
                console.error(error.message);
              });
            })
            .catch((error) => {
              console.error(error.message);
            });
        }
      }
    },
    async saveAccounts() {
      if (this.$user) {
        const userDocRef = doc(db, 'users', this.$user.uid);
        
        await setDoc(userDocRef, this.userDoc);

        const userDocSnap = await getDoc(userDocRef);
        this.userDoc = userDocSnap.data();

        this.snackbar = 'success';
      }
    },
    deleteAccount() {
      if(this.confirmDelete) {
        const auth = getAuth();
        const user = auth.currentUser;

        deleteUser(user).then(() => {
          this.snackbar = 'success';
        }).catch((error) => {
          console.error(error);
        });
      }
    },
    spotifyAuth() {
      if(this.userDoc.spotify_enabled) {
        this.disconnectSpotify();
      } else {
        window.location.href = 'https://auth-3elupsvpoa-nw.a.run.app/login';
      }
    },
    async disconnectSpotify() {
      if (this.$user) {
        const userDocRef = doc(db, 'users', this.$user.uid);
        
        await updateDoc(userDocRef, {
          spotify_access_token: '',
          spotify_refresh_token: '',
          spotify_enabled: false
        });

        const userDocSnap = await getDoc(userDocRef);
        this.userDoc = userDocSnap.data();
      }
    },

    igdbAuth() {
      if(this.userDoc.igdb_enabled) {
        this.userDoc.igdb_access_token = '';
        this.userDoc.igdb_enabled = false;
      } else {
        this.userDoc.igdb_enabled = false;
        
        let self = this;

        axios.get('https://auth-3elupsvpoa-nw.a.run.app/igdb-login')
          .then(async function (response) {
            const userDocRef = doc(db, 'users', self.$user.uid);
        
            await updateDoc(userDocRef, {
              igdb_access_token: response.data.access_token,
              igdb_enabled: true
            });

            const userDocSnap = await getDoc(userDocRef);
            // eslint-disable-next-line require-atomic-updates
            self.userDoc = userDocSnap.data();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    closeSnackbar() {
      this.snackbar = null;
    }
  }
};
</script>
