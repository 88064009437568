<template>
  <div class="card mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-6">
          <h6 class="mb-0">
            {{ title }}
          </h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end align-items-center">
          <i
            class="far fa-calendar-alt me-2"
            aria-hidden="true"
          />
          <small>Gesamter Zeitraum</small>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">
      <form
        role="form"
        action="#"
        class="row mb-3"
        @submit.prevent="addSubmit"
      >
        <div class="col-8 col-lg mb-3">
          <material-input
            id="name"
            v-model="form.name"
            variant="static"
            label="Bezeichnung"
            placeholder=""
          />
        </div>
        <div class="col-4 col-lg-2 order-lg-3 mb-3">
          <material-input
            id="amount"
            v-model.number="form.amount"
            variant="static"
            label="Betrag"
            placeholder="Betrag"
            type="number"
            step="any"
          />
        </div>
        <div class="col-8 col-lg-4 mb-3">
          <div
            class="input-group input-group-static"
          >
            <label>Kategorie</label>
            <select
              id="savingCategory"
              v-model="form.category"
              class="form-control"
              name="savingCategory"
            />
          </div>
        </div>
        <div class="col-4 col-lg-auto order-lg-4 mb-3">
          <material-button
            class="w-100 h-100"
            color="dark"
            variant="outline"
            size="sm"
          >
            <i class="material-icons text-lg">add_circle</i>
          </material-button>
        </div>
      </form>

      <div
        v-for="(element) in savings_categories"
        :key="element"
      >
        <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-1 mt-3">
          {{ element }}
        </h6>
        <ul class="list-group">
          <li
            v-for="(saving) in filteredSavings(element)"
            :key="saving.did"
            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
          >
            <div class="d-flex align-items-center">
              <material-button
                :color="saving.amount == 0 ? 'dark' : (saving.amount > 0 ? 'success' : 'danger')"
                variant="outline"
                size="sm"
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center finance-row"
                @click="deleteEntry(saving)"
              >
                <i
                  :class="'fas ' + (saving.amount == 0 ? 'fa-exclamation' : (saving.amount > 0 ? 'fa-arrow-up' : 'fa-arrow-down'))"
                  aria-hidden="true"
                />
                <i
                  :class="'fas fa-times text-sm'"
                  aria-hidden="true"
                />
              </material-button>
              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">
                  {{ saving.name }}
                </h6>
                <span class="text-xs">{{ formatDate(saving.createdAt) }}</span>
              </div>
            </div>
            <div
              :class="'d-flex align-items-center text-' + (saving.amount == 0 ? 'dark' : (saving.amount > 0 ? 'success text-gradient' : 'danger text-gradient')) + ' text-sm font-weight-bold'"
            >
              {{ formatter.format(saving.amount) }}
            </div>
          </li>
        </ul>
      </div>
      <ul class="list-group">
        <hr class="my-3 horizontal dark">
        <li
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex align-items-center">
            <material-button
              color="success"
              variant="outline"
              size="sm"
              class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
            >
              <i
                class="fas fa-arrow-up"
                aria-hidden="true"
              />
            </material-button>
            <div class="d-flex flex-column">
              <h6 class="mb-0 text-dark text-sm">
                Gesamt
              </h6>
            </div>
          </div>
          <div
            class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold"
          >
            {{ formatter.format(sum) }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";

import { db, doc, getDoc, addDoc, collection, updateDoc, query, where, onSnapshot, deleteDoc } from "@/firebase";
import moment from 'moment';

export default {
  name: "SavingsCard",
  components: {
    MaterialButton,
    MaterialInput
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    form: {
      name: "",
      category: "",
      amount: 0
    },
    savings: [],
    savings_categories: [],
    unsubscribe: null
  }),
  computed: {
    sum() {
      let sum = 0;
      this.savings.forEach((saving) => {
        sum += saving.amount;
      });
      return sum;
    }
  },
  async beforeMount() {
    this.formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });

    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();

      this.savings_categories = this.userDoc.savings_categories.split(',');

      let q = query(collection(db, "savings"), where("user", "==", this.$user.uid));
      this.unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { newIndex, oldIndex, doc, type } = change
          if (type === 'added') {
            this.savings.splice(newIndex, 0, doc.data())
          } else if (type === 'modified') {
            this.savings.splice(oldIndex, 1)
            this.savings.splice(newIndex, 0, doc.data())
          } else if (type === 'removed') {
            this.savings.splice(oldIndex, 1)
          }
        });
      }, (error) => {
        console.error(error);
      });

      if (document.getElementById("savingCategory")) {
        var savingCategory = document.getElementById("savingCategory");
        let categories = [];

        let self = this;

        this.userDoc.savings_categories.split(',').forEach((element, i) => {

          if(i == 0)
            self.form.category = element;

          categories.push({
            value: element,
            label: element,
            selected: (i == 0 ? true : false),
            disabled: false,
          })
        });

        new Choices(savingCategory, {
          choices: categories,
          classNames: {
            containerInner: 'choices__inner pb-0',
          },
          addItemText: (value) => {
          return `Drücke 'Enter' um <b>"${value}"</b> hinzuzufügen.`;
          },
          maxItemText: (maxItemCount) => {
            return `Maximal ${maxItemCount} können hinzugefügt werden.`;
          },
        });
      }
    }
  },
  async beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    async addSubmit() {
      let self = this;

      await addDoc(collection(db, "savings"),       
      {
        ...self.form,
        createdAt: moment().valueOf(),
        user: self.$user.uid
      }).then(async docRef => {
        await updateDoc(docRef, {
          did: docRef.id
        });
      });
    },
    async deleteEntry(saving) {
      await deleteDoc(doc(db, "savings", saving.did));
    },
    formatDate(timestamp) {
      moment.locale('de');
      return moment(timestamp).format('DD. MMMM YYYY') + ' um ' + moment(timestamp).format('HH:mm');
    },
    filteredSavings: function (category) {
      return this.savings.filter(i => i.category === category)
    }
  }
};
</script>
