<template>
  <div class="page-header align-items-start min-vh-100 bg-white">
    <span class="mask bg-gradient-dark opacity-6" />
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-warning shadow-warning border-radius-lg py-3 pe-1"
              >
                <img
                  :src="logoWhite"
                  class="w-100 px-4 my-3"
                  alt="logo"
                >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Anmelden
                </h4>
                <div class="row mt-3">
                  <div class="col-3 text-center ms-auto">
                    <a
                      class="btn btn-link px-1"
                      href="javascript:;"
                    >
                      <i class="fab fa-google text-white text-lg" />
                    </a>
                  </div>
                  <div class="col-3 text-center px-1">
                    <a
                      class="btn btn-link px-1"
                      href="javascript:;"
                    >
                      <i class="fab fa-apple text-white text-lg" />
                    </a>
                  </div>
                  <div class="col-3 text-center me-auto">
                    <a
                      class="btn btn-link px-1"
                      href="javascript:;"
                    >
                      <i class="fab fa-twitter text-white text-lg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div
                v-if="error"
                class="alert alert-danger"
              >
                {{ error }}
              </div>
              <form
                role="form"
                action="#"
                class="text-start mt-3"
                @submit.prevent="submit"
              >
                <div class="mb-3">
                  <material-input
                    id="email"
                    v-model="form.email"
                    type="email"
                    label="E-Mail-Adresse"
                    name="email"
                  />
                </div>
                <div class="mb-3">
                  <material-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    label="Passwort"
                    name="password"
                  />
                </div>
                <material-switch
                  id="rememberMe"
                  v-model="form.remember"
                  name="Remember Me"
                >
                  Angemeldet bleiben
                </material-switch>
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    full-width
                  >
                    Anmelden
                  </material-button>
                </div>
                <p class="mt-4 text-sm text-center">
                  Du hast noch keinen Account?
                  <router-link
                    :to="{ name: 'Signup' }"
                    class="text-warning text-gradient font-weight-bold"
                  >
                    Registrieren
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-muted text-lg-start">
              © {{ new Date().getFullYear() }}, Marvin Carstensen
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://www.marvin-carstensen.com"
                  class="nav-link text-muted"
                  target="_blank"
                >Marvin Carstensen</a>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-muted"
                  :to="{ name: 'Default' }"
                >
                  Datenschutz
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-muted"
                  :to="{ name: 'Default' }"
                >
                  Impressum
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import logoWhite from "@/assets/img/logo-white.svg";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";

export default {
  name: "SigninBasic",
  components: {
    MaterialInput,
    MaterialSwitch,
    MaterialButton,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        remember: false,
      },
      error: null,
      logoWhite,
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    submit() {
      const auth = getAuth();
      console.log(this.form);

      setPersistence(
        auth,
        this.form.remember ? browserLocalPersistence : browserSessionPersistence
      )
        .then(() => {
          signInWithEmailAndPassword(auth, this.form.email, this.form.password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              console.log(user);
              this.$router.push("/");
            })
            .catch((err) => {
              this.error = err.message;
            });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
};
</script>
