<template>
  <div
    class="card card-background align-items-start mt-4"
    :class="`card-background-mask-${color}`"
  >
    <div
      class="full-background cursor-pointer"
      :style="'background-image: url(\'' + cover_url + '\');'"
    />

    <div class="card-body">
      <h5 class="text-white mb-0">
        {{ title }}
      </h5>
      <p class="text-white text-sm">
        {{ singer }}
      </p>
      <div class="d-flex mt-4 pt-2">
        <material-button
          color="white"
          variant="outline"
          class="rounded-circle p-2 mb-0"
          type="button"
          @click="prev"
        >
          <i
            class="p-2 material-icons"
            :class="isRTL ? 'skip_next' : 'skip_previous'"
          >skip_next</i>
        </material-button>
        <material-button
          color="white"
          variant="outline"
          class="rounded-circle p-2 mx-2 mb-0"
          type="button"
          @click="playPause"
        >
          <i
            v-if="is_playing"
            class="material-icons p-2"
          >pause</i>
          <i
            v-else
            class="material-icons p-2"
          >play_arrow</i>
        </material-button>
        <material-button
          color="white"
          variant="outline"
          class="rounded-circle p-2 mb-0"
          type="button"
          @click="next"
        >
          <i
            class="material-icons p-2"
            :class="isRTL ? 'skip_previous' : 'skip_next'"
          >skip_previous</i>
        </material-button>
      </div>
    </div>  
  </div>
</template>

<script>
import Spotify from "spotify-web-api-js";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapState } from "vuex";

import axios from "axios";

import { db, doc, getDoc, setDoc } from "@/firebase";

var spotifyApi;

export default {
  name: "MusicPlayer",
  components: {
    MaterialButton,
  },
  props: {
    color: {
      type: String,
      default: "dark",
    }
  },
  data() {
    return {
      userDoc: {},
      title: 'Wird geladen...',
      singer: 'Wird geladen...',
      cover_url: '',
      is_playing: false,
      spotifyRefresh: null
    };
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  async beforeMount() {
    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();

      if(this.userDoc.spotify_access_token) {
        spotifyApi = new Spotify();
        spotifyApi.setAccessToken(this.userDoc.spotify_access_token);

        this.updatePlayer();
        this.spotifyRefresh = setInterval(this.updatePlayer, 60000);
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.spotifyRefresh)
  },
  methods: {
    async updatePlayer() {
      let self = this;
      try {
        const result = await spotifyApi.getMyCurrentPlayingTrack('', function (err) {
          if (err) {
            var responseJson = JSON.parse(err.response);

            if(responseJson.error.reason == 'NO_ACTIVE_DEVICE') {
              console.log(responseJson.error.reason);
            } else if(responseJson.error.message == 'Invalid access token' || responseJson.error.message == 'The access token expired') {
              self.tryRefreshToken(() => {
                self.updatePlayer()
              });
            }
          }
        });
        if(result && result.item) {
          this.title = result.item.name;
          this.singer = result.item.album.artists[0].name;
          this.cover_url = result.item.album.images[0].url;

          this.is_playing = result.is_playing;
        }
      } catch(e) {
          console.log(e);
          var responseJson = JSON.parse(e.response);
          if(responseJson.error.message == 'Invalid access token' || responseJson.error.message == 'The access token expired') {
            self.tryRefreshToken(() => {
              self.updatePlayer()
            });
          }
      }
    },
    prev() {
      let self = this;
      spotifyApi.skipToPrevious('', function (err) {
        if (err) {
          console.error(err);
          var responseJson = JSON.parse(err.response);
    
          if(responseJson.error.reason == 'NO_ACTIVE_DEVICE') {
            console.log(responseJson.error.reason);
          } else if(responseJson.error.message == 'Invalid access token' || responseJson.error.message == 'The access token expired') {
            self.tryRefreshToken(() => {
              self.prev()
            });
          }
        }
        self.updatePlayer();
      });
    },
    async playPause() {
      if(this.is_playing) {
        let self = this;
        spotifyApi.pause('', function (err) {
          if (err) {
            console.error(err);
            var responseJson = JSON.parse(err.response);
      
            if(responseJson.error.reason == 'NO_ACTIVE_DEVICE') {
              console.log(responseJson.error.reason);
            } else if(responseJson.error.message == 'Invalid access token' || responseJson.error.message == 'The access token expired') {
              self.tryRefreshToken(() => {
                self.playPause()
              });
            }
          }
          self.updatePlayer();
        });
      } else {
        let self = this;
        spotifyApi.play('', function (err) {
          if (err) {
            console.error(err);
            var responseJson = JSON.parse(err.response);
      
            if(responseJson.error.reason == 'NO_ACTIVE_DEVICE') {
              console.log(responseJson.error.reason);
            } else if(responseJson.error.message == 'Invalid access token' || responseJson.error.message == 'The access token expired') {
              self.tryRefreshToken(() => {
                self.playPause()
              });
            }
          }
          self.updatePlayer();
        });
      }
    },
    next() {
      let self = this;
      spotifyApi.skipToNext('', function (err) {
        if (err) {
          console.error(err);
          self.tryRefreshToken(() => {
            self.next()
          });
        }
        self.updatePlayer();
      });
    },

    tryRefreshToken(callback) {
      let self = this;
      axios.get('https://auth-3elupsvpoa-nw.a.run.app/refresh_token?refresh_token=' + this.userDoc.spotify_refresh_token)
      .then(async function (response) {
        console.log("New access token generated.");
        self.userDoc.spotify_access_token = response.data.access_token;
        spotifyApi.setAccessToken(response.data.access_token);

        if (self.$user) {
          const userDocRef = doc(db, 'users', self.$user.uid);
          
          await setDoc(userDocRef, self.userDoc);

          const userDocSnap = await getDoc(userDocRef);
          // eslint-disable-next-line require-atomic-updates
          self.userDoc = userDocSnap.data();
          //callback();
        }
      })
      .catch(function (error) {
        console.error(error);
        callback();
      });
    }
  }
};
</script>
