<template>
  <div class="page-header align-items-start min-vh-100 bg-white">
    <span class="mask bg-gradient-dark opacity-6" />
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-warning shadow-warning border-radius-lg text-center py-3 px-4"
              >
                <img
                  :src="logoWhite"
                  class="w-100 my-3"
                  alt="logo"
                >
                <h4 class="text-white font-weight-bolder mt-2 mb-0">
                  Registieren
                </h4>
                <p class="mb-1 text-white text-sm">
                  E-Mail-Adresse und Passwort eingeben
                </p>

                <div class="row mt-3">
                  <div class="col-3 text-center ms-auto">
                    <a
                      class="btn btn-link px-1"
                      href="javascript:;"
                    >
                      <i class="fab fa-google text-white text-lg" />
                    </a>
                  </div>
                  <div class="col-3 text-center px-1">
                    <a
                      class="btn btn-link px-1"
                      href="javascript:;"
                    >
                      <i class="fab fa-apple text-white text-lg" />
                    </a>
                  </div>
                  <div class="col-3 text-center me-auto">
                    <a
                      class="btn btn-link px-1"
                      href="javascript:;"
                    >
                      <i class="fab fa-twitter text-white text-lg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div
                v-if="error"
                class="alert alert-danger"
              >
                {{ error }}
              </div>
              <form
                role="form"
                class="text-start mt-3"
                @submit.prevent="submit"
              >
                <div class="mb-3">
                  <material-input
                    id="email"
                    v-model="form.email"
                    type="email"
                    label="E-Mail-Adresse"
                    name="email"
                  />
                </div>
                <div class="mb-3">
                  <material-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    label="Passwort"
                    name="password"
                  />
                </div>
                <material-checkbox
                  id="flexCheckDefault"
                  v-model="form.agb"
                  class="font-weight-light"
                  checked
                >
                  Ich stimme den
                  <a
                    href="javascript;"
                    class="text-dark font-weight-bolder"
                  >AGB</a> zu.
                </material-checkbox>
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    full-width
                  >
                    Registieren
                  </material-button>
                </div>
                <p class="mt-4 text-sm text-center">
                  Du hast bereits einen Account?
                  <router-link
                    :to="{ name: 'Signin' }"
                    class="text-warning text-gradient font-weight-bold"
                  >
                    Anmelden
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-muted text-lg-start">
              © {{ new Date().getFullYear() }}, Marvin Carstensen
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://www.marvin-carstensen.com"
                  class="nav-link text-muted"
                  target="_blank"
                >Marvin Carstensen</a>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-muted"
                  :to="{ name: 'Default' }"
                >
                  Datenschutz
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-muted"
                  :to="{ name: 'Default' }"
                >
                  Impressum
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import logoWhite from "@/assets/img/logo-white.svg";

import { db, doc, getAuth, setDoc, createUserWithEmailAndPassword } from "@/firebase";

export default {
  name: "SignupBasic",
  components: {
    MaterialInput,
    MaterialCheckbox,
    MaterialButton,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        agb: false,
      },
      error: null,
      logoWhite,
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    submit() {
      const auth = getAuth();
      console.log(this.form);

      createUserWithEmailAndPassword(auth, this.form.email, this.form.password)
        .then(async (userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(user);

          await setDoc(doc(db, "users", user.uid), {});

          this.$router.push("/");
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
  },
};
</script>
