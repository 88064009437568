<template>
  <div class="nav-wrapper position-relative end-0">
    <ul
      class="nav nav-pills nav-fill p-1"
      role="tablist"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="tab"
        class="nav-item"
      >
        <a
          :id="'pills-wish' + index + '-tab'"
          :class="'nav-link mb-0 px-0 py-1 ' + (index == 0 ? 'active' : '')"
          data-bs-toggle="pill"
          :href="'#pills-wish' + index"
          role="tab"
          :aria-controls="'pills-wish' + index"
          :aria-selected="index == 0 ? 'true' : 'false'"
        >{{ tab.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "NavPill",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  mounted() {

  },
};
</script>
