<template>
  <div class="nav-wrapper position-relative end-0">
    <ul
      class="nav nav-pills nav-fill p-1"
      role="tablist"
    >
      <li class="nav-item">
        <a
          id="pills-movies-tab"
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="pill"
          href="#pills-movies"
          role="tab"
          aria-controls="pills-movies"
          aria-selected="true"
        >Filme</a>
      </li>
      <li class="nav-item">
        <a
          id="pills-shows-tab"
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="pill"
          href="#pills-shows"
          role="tab"
          aria-controls="pills-shows"
          aria-selected="false"
        >Serien</a>
      </li>
      <li class="nav-item">
        <a
          id="pills-games-tab"
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="pill"
          href="#pills-games"
          role="tab"
          aria-controls="pills-games"
          aria-selected="false"
        >Videospiele</a>
      </li>
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "NavPill",
  mounted() {
    setNavPills();
  },
};
</script>
