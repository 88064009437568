<template>
  <div class="pt-2 container-fluid">
    <div class="row mb-4 align-items-center">
      <div class="col-12 col-lg-3 mb-2 mb-lg-4">
        <nav-pill />
      </div>
      <div class="col mb-4">
        <material-input
          id="search"
          v-model="searchTerm"
          type="text"
          label="Suchen..."
        />
      </div>
      <div class="col-auto col-lg-auto mb-4">
        <material-button
          class="w-100 h-100"
          color="warning"
          variant="outline"
          size="sm"
          @click="openModal()"
        >
          <i class="material-icons text-lg">post_add</i>
        </material-button>
      </div>
      <div class="col-auto col-lg-auto mb-4">
        <material-button
          class="w-100 h-100"
          color="warning"
          variant="outline"
          size="sm"
          @click="changeSorting()"
        >
          <i
            v-if="sorting == 'date'"
            class="material-icons text-lg"
          >sort_by_alpha</i>
          <i
            v-if="sorting == 'alpha'"
            class="material-icons text-lg"
          >sort</i>
        </material-button>
      </div>
    </div>
    <div
      id="pills-tabContent"
      class="tab-content"
    >
      <div
        id="pills-movies"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="pills-moviews-tab"
      >
        <div class="row mt-3">
          <div
            v-for="movie in movies"
            :key="movie.id"
            class="col-lg-auto mb-5"
          >
            <watchlist-card
              :img="movie.poster_path"
              :title="movie.title"
              :description="movie.overview ? movie.overview : ''"
              :price="String(movie.release_date ? movie.release_date : '')"
              location="Netflix"
              :did="movie.did"
              type="Filme"
            />
          </div>
        </div>
      </div>
      <div
        id="pills-shows"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="pills-shows-tab"
      >
        <div class="row mt-3">
          <div
            v-for="show in shows"
            :key="show.id"
            class="col-lg-auto mb-5"
          >
            <watchlist-card
              :img="show.poster_path"
              :title="show.name"
              :description="show.overview ? show.overview : ''"
              :price="String(show.first_air_date ? show.first_air_date : '')"
              location="Netflix"
              :did="show.did"
              type="Serien"
            />
          </div>
        </div>
      </div>
      <div
        id="pills-games"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="pills-games-tab"
      >
        <div class="row mt-3">
          <div
            v-for="game in games"
            :key="game.id"
            class="col-lg-auto mb-5"
          >
            <watchlist-card
              :img="game.img_url"
              :title="game.name"
              :description="game.summary ? game.summary : ''"
              :price="String(game.first_release_date ? game.first_release_date : '')"
              :location="game.platform ? game.platform : ''"
              :did="game.did"
              type="Videospiele"
            />
          </div>
        </div>
      </div>
    </div>
  </div>


  <div
    id="watchlist-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="h5 modal-title">
            {{ mode }} hinzufügen
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body">
          <material-input
            id="search"
            v-model="searchTerm"
            type="text"
            label="Suchen..."
          />

          <div class="row mt-5">
            <div
              v-for="(result, index) in searchResults"
              :key="result.id"
              class="col-lg-3 col-md-6 mb-5"
            >
              <watchlist-search-card
                :img="result.img_url ? result.img_url : result.poster_path"
                :title="result.name ? result.name : result.title"
                :description="result.summary ? result.summary : (result.overview ? result.overview : '')"
                :price="String(result.first_release_date ? result.first_release_date : (result.release_date ? result.release_date : (result.first_air_date ? result.first_air_date : '')))"
                :location="result.platform ? result.platform : 'Netflix'"
                @click="addEntry(result, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="watchlist-modal-backdrop"
    class="fixed inset-0 z-40 hidden bg-black opacity-50"
  />
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import WatchlistCard from "./components/WatchlistCard.vue";
import WatchlistSearchCard from "./components/WatchlistSearchCard.vue";
import NavPill from "./components/WatchlistNavPill.vue";

import { db, doc, getDoc, addDoc, updateDoc, collection, query, where, onSnapshot, orderBy } from "@/firebase";
import axios from "axios";
import { debounce } from "debounce";

export default {
  name: "DataTables",
  components: {
    MaterialButton,
    MaterialInput,
    WatchlistCard,
    WatchlistSearchCard,
    NavPill
  },
  data() {
    return {
      searchTerm: '',
      mode: '',
      searchResults: [],
      games: [],
      movies: [],
      shows: [],
      sorting: 'alpha'
    };
  },
  watch: {
    searchTerm: debounce(async function(val) {
      if(val != null && val != '') {

        let response = '';

        this.searchResults = [];

        switch(this.mode) {
          case 'Filme':
            response = await axios.get('https://api.themoviedb.org/3/search/movie?api_key=' + this.userDoc.tmdb_api_key + '&language=de-DE&include_adult=false&page=1&query=' + this.searchTerm);
            break;
          case 'Serien':
            response = await axios.get('https://api.themoviedb.org/3/search/tv?api_key=' + this.userDoc.tmdb_api_key + '&language=de-DE&include_adult=false&page=1&query=' + this.searchTerm);
            break;
          case 'Videospiele':
            response = await axios.get('https://auth-3elupsvpoa-nw.a.run.app/igdb-search?access_token=' + this.userDoc.igdb_access_token + '&query=' + this.searchTerm);
            break;
        }

        switch(this.mode) {
          case 'Filme':
            response.data.results.forEach(element => {
              if(element.poster_path != null) {
                element.poster_path = "https://image.tmdb.org/t/p/w185_and_h278_bestv2" + element.poster_path;
                if(!element.release_date)
                  element.release_date = 'Unbekannt';
                this.searchResults.push(element); 
              }
            });
            break;
          case 'Serien':
            response.data.results.forEach(element => {
              if(element.poster_path != null) {
                element.poster_path = "https://image.tmdb.org/t/p/w185_and_h278_bestv2" + element.poster_path;
                if(!element.first_air_date)
                  element.first_air_date = 'Unbekannt';
                this.searchResults.push(element);
              }
            });
            break;
          case 'Videospiele':
            response.data.forEach(element => {
              if(element.cover != null && element.cover.image_id != null) {
                element.img_url = "https://images.igdb.com/igdb/image/upload/t_cover_big/" + element.cover.image_id + ".jpg";

                if(element.platforms && element.platforms.length > 0)
                  element.platform = element.platforms[0].name

                if(!element.first_release_date)
                  element.first_release_date = 'Unbekannt';
                this.searchResults.push(element);
              }
            });
            break;
        }
      }
    }, 500)
  },
  async beforeMount() {
    this.initWatchlist()
  },
  async beforeUnmount() {
    this.unsubscribeMovies();
    this.unsubscribeShows();
    this.unsubscribeGames();
  },
  methods: {
    async initWatchlist() {
      if (this.$user) {
        const userDocRef = doc(db, 'users', this.$user.uid);
        let userDocSnap = await getDoc(userDocRef);
        this.userDoc = userDocSnap.data();

        let ordering = this.sorting == 'date' ? orderBy("release_date", "desc") : orderBy("title");

        const qMovies = query(collection(db, "movies"), where("user", "==", this.$user.uid), ordering);
        this.unsubscribeMovies = onSnapshot(qMovies, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const { newIndex, oldIndex, doc, type } = change
            if (type === 'added') {
              this.movies.splice(newIndex, 0, doc.data())
            } else if (type === 'modified') {
              this.movies.splice(oldIndex, 1)
              this.movies.splice(newIndex, 0, doc.data())
            } else if (type === 'removed') {
              this.movies.splice(oldIndex, 1)
            }
          });
        }, (error) => {
          console.error(error);
        });

        ordering = this.sorting == 'date' ? orderBy("first_air_date", "desc") : orderBy("name");

        const qShows = query(collection(db, "shows"), where("user", "==", this.$user.uid), ordering);
        this.unsubscribeShows = onSnapshot(qShows, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const { newIndex, oldIndex, doc, type } = change
            if (type === 'added') {
              this.shows.splice(newIndex, 0, doc.data())
            } else if (type === 'modified') {
              this.shows.splice(oldIndex, 1)
              this.shows.splice(newIndex, 0, doc.data())
            } else if (type === 'removed') {
              this.shows.splice(oldIndex, 1)
            }
          });
        }, (error) => {
          console.error(error);
        });

        ordering = this.sorting == 'date' ? orderBy("first_release_date", "desc") : orderBy("name");

        const qGames = query(collection(db, "games"), where("user", "==", this.$user.uid), ordering);
        this.unsubscribeGames = onSnapshot(qGames, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const { newIndex, oldIndex, doc, type } = change
            if (type === 'added') {
              this.games.splice(newIndex, 0, doc.data())
            } else if (type === 'modified') {
              this.games.splice(oldIndex, 1)
              this.games.splice(newIndex, 0, doc.data())
            } else if (type === 'removed') {
              this.games.splice(oldIndex, 1)
            }
          });
        }, (error) => {
          console.error(error);
        });
      }
    },
    async addEntry(entry, index) {
      this.searchResults.splice(index, 1);

      entry.user = this.$user.uid;

      switch(this.mode) {
        case 'Filme':
          await addDoc(collection(db, "movies"), entry)
          .then(async docRef => {
            console.log("Document written with ID: ", docRef.id);
            await updateDoc(docRef, {
              did: docRef.id
            });
          });
          break;
        case 'Serien':
          await addDoc(collection(db, "shows"), entry)
          .then(async docRef => {
            console.log("Document written with ID: ", docRef.id);
            await updateDoc(docRef, {
              did: docRef.id
            });
          });
          break;
        case 'Videospiele':
          await addDoc(collection(db, "games"), entry)
          .then(async docRef => {
            console.log("Document written with ID: ", docRef.id);
            await updateDoc(docRef, {
              did: docRef.id
            });
          });
          break;
      }
    },

    openModal() {
      const bootstrap = this.$store.state.bootstrap;
      let watchlistModalElement = document.getElementById(
        "watchlist-modal"
      );

      const navPill = document.querySelectorAll('.nav-pills .nav-link.active');
      let mode = navPill[0].textContent;
      this.mode = mode;

      this.searchTerm = '';
      this.searchResults = [];

      var watchlistModal = new bootstrap.Modal(watchlistModalElement, {
        show: true
      });
      watchlistModal.show();
    },
    changeSorting() {
      if(this.sorting == 'date') {
        this.sorting = 'alpha';
      } else if(this.sorting == 'alpha') {
        this.sorting = 'date';
      }

      this.games = [];
      this.movies = [];
      this.shows = [];

      this.unsubscribeMovies();
      this.unsubscribeShows();
      this.unsubscribeGames();

      this.initWatchlist();
    }
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 992px) {
  .card {
    width: 240px;
  }
}
</style>
