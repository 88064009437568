// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, updatePassword, signInWithEmailAndPassword, createUserWithEmailAndPassword, deleteUser, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, getDocs, addDoc, setDoc, updateDoc, deleteDoc, onSnapshot, collection, where, query, orderBy, limit } from "firebase/firestore";
import { enableIndexedDbPersistence } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBeRMvzc4krgY1jBrfLb2OABvbVnK2u9o0",
  authDomain: "dotter-app.firebaseapp.com",
  projectId: "dotter-app",
  storageBucket: "dotter-app.appspot.com",
  messagingSenderId: "465835072374",
  appId: "1:465835072374:web:8512324c12a5774aad3857",
  measurementId: "G-GHL562X60V",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);

enableIndexedDbPersistence(db)
  .catch((err) => {
      if (err.code == 'failed-precondition') {
        console.error("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
      } else if (err.code == 'unimplemented') {
        console.error("The current browser does not support all of the features required to enable persistence");
      }
  });

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

async function log(message, icon, color, userId) {
  const docRef = await addDoc(collection(db, "logs"), {
    message: message,
    icon: icon,
    color: color,
    user: userId,
    createdAt: new Date().toISOString()
  });
  console.log("Document written with ID: ", docRef.id);
}

/*async function test() {
  const user = await getCurrentUser();
  if (user) {
    try {
      const docRef = await setDoc(doc(db, "users", user.uid), {
        first: "Ada",
        last: "Lovelace",
        born: 1816,
      });
      console.log("Document written with ID: ", docRef);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
}

test();*/

export { firebase, db, getCurrentUser, doc, getDoc, getDocs, addDoc, setDoc, updateDoc, deleteDoc, onSnapshot, collection, where, query, getAuth, updatePassword, signInWithEmailAndPassword, createUserWithEmailAndPassword, deleteUser, onAuthStateChanged, log, orderBy, limit };
