<template>
  <div class="pt-2 container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-lg mb-2 mb-lg-4">
        <nav-pill
          :tabs="tabs"
          :style="'max-width: 500px;'"
        />
      </div>
      <div class="col-6 col-lg-auto mb-4">
        <material-button
          class="w-100 h-100"
          color="warning"
          variant="outline"
          size="sm"
          @click="openAddModal"
        >
          <i class="material-icons text-2xl">post_add</i>
        </material-button>
      </div>
      <div class="col-6 col-lg-auto mb-4">
        <material-button
          class="w-100 h-100"
          color="warning"
          variant="outline"
          size="sm"
          @click="sync"
        >
          <i class="material-icons text-2xl">sync</i>
        </material-button>
      </div>
    </div>

    <div
      id="pills-tabContents"
      class="tab-content"
    >
      <div
        v-for="(tab, index) in tabs"
        :id="'pills-wish' + index"
        :key="tab.title + index"
        :class="'tab-pane fade ' + (index == 0 ? 'show active' : '')"
        role="tabpanel"
        :aria-labelledby="'pills-wish' + index + '-tab'"
      >
        <div
          v-if="tab.title != 'Eigene'"
          class="row mt-3"
        >
          <div        
            v-for="wish in tab.items"
            :key="wish.id"
            class="col-lg-auto mb-5"
          >
            <wishlist-card
              :wish="wish"
              :wishlist-id="tab.did"
              :img="wish.imgUrl"
              :title="wish.title"
              description=""
              :price="wish.price + ' ' + wish.currency"
              :location="tab.title == 'Eigene' ? 'Extern' : 'Amazon'"
            />
          </div>
        </div>

        <draggable 
          v-if="tab.title == 'Eigene'"
          v-model="tab.items"
          tag="transition-group"
          :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
          v-bind="dragOptions"
          item-key="id"
          class="row mt-3"
          @start="drag = true"
          @change="onChange"
          @end="drag = false"
        >
          <template #item="{element}">
            <div class="col-lg-auto mb-5">
              <wishlist-card
                :wish="element"
                :wishlist-id="tab.did"
                :img="element.imgUrl"
                :title="element.title"
                description=""
                :price="element.price + ' ' + element.currency"
                :location="tab.title == 'Eigene' ? 'Extern' : 'Amazon'"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>


  <div
    id="wish-add-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="h5 modal-title">
            Wunsch hinzufügen
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body">
          <form
            role="form"
            action="#"
            class="text-start mt-3"
            @submit.prevent="addSubmit"
          >
            <div class="mb-3">
              <material-input
                id="title"
                v-model="addForm.title"
                type="text"
                label="Titel"
                name="title"
              />
            </div>            
            <div class="mb-3">
              <material-input
                id="link"
                v-model="addForm.link"
                type="text"
                label="URL"
                name="link"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="imgUrl"
                v-model="addForm.imgUrl"
                type="text"
                label="Bild-URL"
                name="imgUrl"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="price"
                v-model="addForm.price"
                type="text"
                label="Preis"
                name="price"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="currency"
                v-model="addForm.currency"
                type="text"
                label="Währung"
                name="currency"
              />
            </div>

            <div class="text-center">
              <material-button
                class="my-4 mb-2"
                variant="gradient"
                color="warning"
                full-width
              >
                Hinzufügen
              </material-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    id="wish-edit-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="h5 modal-title">
            Wunsch bearbeiten
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body">
          <form
            role="form"
            action="#"
            class="text-start mt-3"
            @submit.prevent="editSubmit"
          >
            <div class="mb-3">
              <material-input
                id="title"
                v-model="editForm.title"
                type="text"
                label="Titel"
                name="title"
              />
            </div>            
            <div class="mb-3">
              <material-input
                id="link"
                v-model="editForm.link"
                type="text"
                label="URL"
                name="link"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="imgUrl"
                v-model="editForm.imgUrl"
                type="text"
                label="Bild-URL"
                name="imgUrl"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="price"
                v-model="editForm.price"
                type="text"
                label="Preis"
                name="price"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="currency"
                v-model="editForm.currency"
                type="text"
                label="Währung"
                name="currency"
              />
            </div>

            <div class="text-center">
              <material-button
                class="my-4 mb-2"
                variant="gradient"
                color="warning"
                full-width
              >
                Speichern
              </material-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    id="wish-modal-backdrop"
    class="fixed inset-0 z-40 hidden bg-black opacity-50"
  />
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import WishlistCard from "./components/WishlistCard.vue";
import NavPill from "./components/WishlistNavPill.vue";
import draggable from 'vuedraggable'

import { db, doc, getDoc, collection, query, where, onSnapshot, updateDoc, setDoc } from "@/firebase";
import axios from "axios";

import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "DataTables",
  components: {
    MaterialButton,
    MaterialInput,
    WishlistCard,
    NavPill,
    draggable,
  },

  data() {
    return {
      drag: false,
      tabs: [],
      addForm: {
        currency: "",
        imgUrl: "",
        link: "",
        price: "",
        title: ""
      },
      editForm: {
        currency: "",
        imgUrl: "",
        link: "",
        price: "",
        title: ""
      },
      wishAddModal: null,
      wishEditModal: null
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  async beforeMount() {
    if (this.$user) {
      const userDocRef = doc(db, 'users', this.$user.uid);
      let userDocSnap = await getDoc(userDocRef);
      this.userDoc = userDocSnap.data();

      const q = query(collection(db, "wishlists"), where("user", "==", this.$user.uid));
      this.unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { newIndex, oldIndex, doc, type } = change
          if (type === 'added') {
            this.tabs.splice(newIndex, 0, doc.data())
          } else if (type === 'modified') {
            this.tabs.splice(oldIndex, 1)
            this.tabs.splice(newIndex, 0, doc.data())
          } else if (type === 'removed') {
            this.tabs.splice(oldIndex, 1)
          }
        });
      }, (error) => {
        console.error(error);
      });
    }
  },
  async beforeUnmount() {
    this.unsubscribe();
  },
  updated() {
    if (this.$user && this.userDoc) {
      let listCount = this.userDoc.amazon_wishlists.split(',').length + 1; // + Personal List
      
      if(this.tabs.length == listCount) {
        setNavPills();
      }
    }
  },
  methods: {
    openAddModal() {
      const bootstrap = this.$store.state.bootstrap;
      let wishAddModalElement = document.getElementById(
        "wish-add-modal"
      );
      this.wishAddModal = new bootstrap.Modal(wishAddModalElement, {
        show: true
      });
      this.wishAddModal.show();
    },
    async addSubmit() {
      let wish = this.addForm;
      wish.user = this.$user.uid;

      let wishes = [];

      try {
        const pWishlistDocRef = doc(db, 'wishlists', this.$user.uid);
        let pWishlistDocSnap = await getDoc(pWishlistDocRef);

        if(pWishlistDocSnap) {
          wishes = pWishlistDocSnap.data().items;
        }
      } catch (error) {
        console.log(error);
      }
      wishes.push(wish);

      await setDoc(doc(db, "wishlists", wish.user), {
        user: wish.user,
        title: 'Eigene',
        items: wishes,
        did: wish.user
      });
    },
    openEditModal(wish) {
      const bootstrap = this.$store.state.bootstrap;
      
      this.editForm = wish;

      let wishEditModalElement = document.getElementById(
        "wish-edit-modal"
      );
      this.wishEditModal = new bootstrap.Modal(wishEditModalElement, {
        show: true
      });
      this.wishEditModal.show();
    },
    async editSubmit() {
      let wish = this.editForm;
      let wishes = [];

      const pWishlistDocRef = doc(db, 'wishlists', wish.user);
      let pWishlistDocSnap = await getDoc(pWishlistDocRef);
      wishes = pWishlistDocSnap.data().items;

      wishes.forEach((element, i) => {
        if(wish.title == element.title) {
          wishes[i] = wish;
        }
      });

      await updateDoc(doc(db, "wishlists", wish.user), {
        items: wishes,
      })
        .then(() => {
          this.wishEditModal.hide();
        });
    },
    async sync() {
      let self = this;

      const ids = this.userDoc.amazon_wishlists.split(',');
      ids.forEach(async element => {
        let response = await axios.get('https://auth-3elupsvpoa-nw.a.run.app/amazon?id=' + element);

        await setDoc(doc(db, "wishlists", element), {
          user: self.$user.uid,
          title: response.data.title,
          items: response.data.items,
          did: element
        });
      });
    },
    onChange(e) {
      if(e.moved) {
        this.tabs.forEach(async (tab) => {
          if(tab.did == e.moved.element.user) {
            await updateDoc(doc(db, "wishlists", e.moved.element.user), {
              items: tab.items,
            })
              .then(() => {
                console.log("Success")
              });
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ghost {
  opacity: 0.2;
}
@media (min-width: 992px) {
  .card {
    width: 295px;
  }
}
</style>
